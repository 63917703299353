.update-input-textarea {
    width: 100%;
    height: 5rem;
    border-radius: .8rem;
    padding: .5rem 1.5rem;
    background-color: $color-primary-light-4;
    border: none;
    color: white;

    &::placeholder {
        font-weight: 500;
        line-height: 2.4rem;
        text-align: left;
        color: $color-white;
    }

    &:valid {
        outline: none;
        border-bottom: 2px solid green;
    }

    &:focus:invalid {
        outline: none;
        border-bottom: 2px solid red;
    }

}

.update-input-phone {
    width: 100%;
    height: 5rem;
    border-radius: .8rem;
    padding: .5rem 1.5rem;
    background-color: $color-primary-light-4;
    color: $color-white;
    border: none;

    &::placeholder {
        font-weight: 500;
        line-height: 2.4rem;
        text-align: left;
        color: $color-white;
    }

    &:valid {
        outline: none;
        border-bottom: 2px solid green;
    }

    &:focus:invalid {
        outline: none;
        border-bottom: 2px solid red;
    }
}

.update-input-wrapper {
    width: 45%;
    position: relative;
}

.update-input-date {
    width: 100%;
    height: 5rem;
    border-radius: .8rem;
    padding: .5rem 1.5rem;
    background-color: $color-primary-light-4;
    border: none;
    color: $color-white;

    &:focus {
        outline: none;
    }

    @media (max-width: 50em) {
        z-index: 1;
        position: relative;
    }
}

.update-input-time {
    width: 100%;
    height: 5rem;
    border-radius: .8rem;
    padding: .5rem 1.5rem;
    background-color: $color-primary-light-4;
    border: none;
    color: $color-white;

    &:focus {
        outline: none;
    }

    @media (max-width: 50em) {
        z-index: 1;
        position: relative;
    }
}

.update-input-placeholder {
    position: absolute;
    display: inline-block;
    top: 25%;
    left: 1.6rem;
    width: 60%;
    background-color: $color-primary-light-4;
    border: none;
    font-weight: 500;
    line-height: 2.4rem;
    text-align: left;
    color: $color-white;
    font-size: 1.2rem;

    @media (max-width: 50em) {
        font-size: 1.6rem;
    }
}

.update-input-icon-box{
    position: absolute;
    background-color: $color-primary-light-4;
    top: 35%;
    right: 1.6rem;
    width: 1.6rem;
    pointer-events: none;
}

.update-input-icon {
    position: relative;
    width: 1.4rem;
    height: 1.4rem;
    pointer-events: none;
    fill: $color-white;
    pointer-events: none;
}

.update-input-hidden {
    display: none;
}

.update-input-submit-button {
    width: 100%;
    height: 5rem;
    background-color: $color-white;
    border-radius: .8rem;
    color: $color-primary;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.4rem;
    border: none;
    box-shadow: 0rem .6rem 1rem rgba($color-black-dark, 0.4);
    transition: all .1s;
    cursor: pointer;

    &:active {
        transform: translateY(1px);
    }
}

.update-input-select {
    width: 100%;
    height: 5rem;
    border-radius: .8rem;
    padding: .5rem 1.5rem;
    background-color: $color-primary-light-4;
    border: none;
    color: white;

    &::placeholder {
        font-weight: 500;
        line-height: 2.4rem;
        text-align: left;
        color: $color-white;
    }

    &:valid {
        outline: none;
        border-bottom: 2px solid green;
    }

    &:focus:invalid {
        outline: none;
        border-bottom: 2px solid red;
    }
}

.update-input-email {
    width: 100%;
    height: 5rem;
    margin-bottom: 2rem;
    padding: .5rem 1.5rem;
    border-radius: .8rem;
    background-color: $color-primary-light-4;
    border: none;

    &::placeholder {
        font-weight: 500;
        line-height: 2.4rem;
        text-align: left;
        color: $color-black-dark;
    }

    &:valid {
        outline: none;
        border-bottom: 2px solid green;
    }

    &:focus:invalid {
        outline: none;
        border-bottom: 2px solid red;
    }

}