@keyframes borderAnimation {
    0%{
        width: 0%;
    }

    100%{
        width: 100%;
    }
}

@keyframes moveToRight{
    0%{
        transform: translateX(-20rem);
        opacity: 0;
    }

    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes moveToLeft{
    0%{
        transform: translateX(20rem);
        opacity: 0;
    }

    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeOut {
    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }   
}

@keyframes drop {
    0%{
        opacity: 0;
        transform: translateY(-50rem);
    }
    100%{
        opacity:1;
        transform: translateY(0rem) ;
    }

}