.bookCabHeader {
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary;
    color: $color-white;
    text-align: center; // Ensures proper alignment on smaller screens

    &__container {
        width: 80%;
        display: flex;
        align-items: center;
        // justify-content: space-between; // Keeps content organized
        flex-wrap: wrap; // Prevents overflow on small screens
    }

    &__title {
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 2.4rem;
        margin-right: 2.4rem;

        @media (max-width: 900px) {
            font-size: 2rem; 
            line-height: 2.2rem;
            margin-right: 1.5rem;
        }

        @media (max-width: 480px) {
            font-size: 1.8rem; 
            line-height: 2rem;
            margin-right: 1rem;
            text-align: center;
            width: 100%; // Full width for small screens
        }
    }

    &__details {
        display: flex;
        flex-wrap: wrap; 
        gap: 1rem; // Adds spacing between details
        justify-content: center; // Centers details on small screens
    }

    &__details-item {
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.6rem;

        @media (max-width: 768px) {
            font-size: 1.8rem;
            line-height: 2.4rem;
        }

        @media (max-width: 480px) {
            font-size: 1.6rem;
            line-height: 2.2rem;
            text-align: center;
        }
    }

    @media (max-width: 768px) {
        height: auto;
        padding: 2rem 1rem; // Adds spacing for better responsiveness
    }
}
