.clientCard{
    box-shadow: .3rem .6rem  1rem #79636824;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    margin-left: 0rem;
    


    &__img{
      width: 13rem;
      height: 13rem;
      padding: 2.4rem 1.8rem;
    }
}