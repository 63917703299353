.reviewCard{
    display: inline-block;
    border: 1px solid $color-grey-light-2;
    font-size: 1.6rem;
    line-height: 2.4rem;
    border-radius: 1rem;

    &:not(:last-child){
        margin-right: 2rem;
    }

    &__img-box{
       display: flex;
       align-items: center;
       justify-content: center;
       width: 100%;
       height: 15rem;
       margin-bottom: 1.5rem;
    }

    &__img{
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        object-fit: cover;
    }

    &__detail{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
    }

    &__review{
        font-weight: 400;
        color: $color-black-dark;
        width: 33rem;
        height: 9.8rem;
        overflow: hidden;
        padding: 0rem 3rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    &__name{
        font-weight: 700;
        color: $color-black-dark;
        margin-bottom: .5rem;
    }

    &__type{
        font-weight: 400;
        color: rgba($color-black-dark,0.5);
    }
}