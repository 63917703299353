.earn-partner{
    display: flex;
    justify-content: center;
    padding: 3rem 4rem;
    min-height: 60vh;

    &__container{
        width: 90%;
        display: flex;
        
        @media (max-width: 50em) {
            flex-direction: column;
            align-items: center;
        }
    }

    &__box1{
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 50em) {
            width: 100%;
            align-items: center;
            order: 2;
        }
    }

    &__content{
        text-align: left;

        @media (max-width: 50em) {
            text-align: center;
        }

        & > p{
            padding-right: 25%;
            margin-top: 1.5rem;
            @media (max-width: 50em) {
                padding-right: 0rem;
                font-size: 1.8rem;
            }
        }  

        & > h1{
            line-height: 5.7rem;
            margin-bottom: 1.5rem;
        }
    }

    &__box2{
        width: 30%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center; 
        background-image: url(../../../../public/images/mobilebg.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 30.8rem;

        @media (max-width: 50em) {
            width: 100%;
            order: 1;
            margin-bottom: 2rem;
        }
    }
 
    &__img{
        // width: 70%;
        // height: 55%; 
        // z-index: 2;
    }

    
}