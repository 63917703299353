.blogCard{

    border-radius: 1rem;
    background-color: $color-white;
    

    &:not(:last-child){
        margin-right: 2rem;
    }
    
    &__img-box{
       width: 35.4rem;
       height: 27rem;
    }

    &__img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }

    &__date{
      color: $color-primary;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 2.1rem;
      margin: 1rem 1rem;
    }

    &__topic{
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.6rem;
        color: $color-secondary;
        margin: 1rem 1rem;
    }

    &__des{
        font-size: 1.3rem;
        line-height: 2.2rem;
        font-family: 400;
        color: $color-black-dark;
        margin: 1rem 1rem;
        height: 6.2rem;
        overflow: hidden;
    }

    &__button{
         border-radius: .8rem;
         background-color: $color-primary;
         text-align: center;
         color: $color-white;
         font-size: 1.6rem;
         font-weight: 700;
         width: 90%;
         border: none;
         height: 4.8rem;
         margin: 2rem 1rem;
         text-decoration: none;
         cursor: pointer;
    }
}