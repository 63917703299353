.serviceSection{
    background-color: $color-primary;
    padding: 3rem 0rem;
    margin-top: -8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 62.5em) {
        margin-top: 0rem;
    }
  
}