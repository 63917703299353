.partner-benefit{
    width: 100%;

    @media (max-width: 62.5em) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    &__card{
        display: flex;
        align-items: center;
        &:not(:last-child){
            margin-bottom: 4rem;
        }
        
        @media (max-width: 62.5em) {
            width: 60%;
            margin: 3rem 0rem;
        }
        
        @media (max-width: 50em) {
            width: 100%;
            margin: 3rem 0rem;
        }
    }

    &__icon-box{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 7.2rem;
        height: 7.2rem;
        border-radius: 1rem;
        background-color: $color-secondary;
        margin-right: 2rem;
        flex-shrink: 0;
    }

    &__icon{
       width: 4rem;
       height: 4rem;
       fill: $color-white;
    }

    &__detail{

        &-heading{
            font-size: 2rem;
            font-weight: 600;
            line-height: 2.3rem;
            color: $color-black;  
        }

        &-para{
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 2.3rem;
            color: $color-black;            
        }
    }
}