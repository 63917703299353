.recentBlogCard{
    height: 20rem;
    border-radius: 1rem;
    margin-bottom: 3rem;
    cursor: pointer;

    &__img{
        width: 100%;
        height: 100%;
        border-radius: 1rem;
    }
}