.login {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    @media (max-width: 62.5rem) {
        min-height: 0vh;
    }


    &__container {
        width: 90%;
        display: flex;
        @media (max-width: 62.5rem) {
            width: 95%;
            margin-top: 25%;
        }
    }


    &__image-container {
        width: 45%;


        @media (max-width: 62.5rem) {
            display: none;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }

    &__content {
        width: 55%;
        display: flex;
        justify-content: center;


        @media (max-width: 62.5rem) {
            width: 100%;
        }
    }

    &__form-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;

        @media (max-width: 62.5rem) {
            width: 90%;
        }
    }

    &__logo {
        width: 13.6rem;
        height: 4.8rem;
        margin-bottom: 3rem;
    }

    &__form {
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;
        box-shadow: 0rem .4rem .48rem 0rem $color-black-light-6;
        width: 100%;
        padding: 2rem 2rem;
        border-radius: 1rem;
    }

    &__heading {
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 5.76rem;
        color: $color-black;
    }

    &__subheading {
        font-size: 1.2rem;
        font-weight: 500;
        color: $color-black;
        margin-top: -1.5rem;
        margin-bottom: 1.5rem;
    }

    &__form-group {
        display: flex;
        width: 98%;

        @media (max-width: 62.5rem) {
            flex-direction: column;
        }
    }

    &__input-wrapper {
        width: 47.5%;

        &:not(:last-child) {
            margin-right: 5%;
        }

        @media (max-width: 62.5rem) {
            width: 100%;
            margin-right: 0%;
        }
    }

    &__form-actions {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0rem 1rem;
    }

    &__form-actions--forgot {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 0rem 1rem;
    }

    &__remember-me {
        display: flex;
        align-items: center;
        font-family: Montserrat;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 5.76rem;
        color: $color-black;
    }

    &__button {
        width: 14rem;
    }

    &__redirect {
        display: flex;
        padding: 2rem;
        width: 100%;
        box-shadow: 0px 4px 4.8px 0px $color-black-light-6;
        align-items: center;
        border-radius: 1rem;

        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        &-icon {
            width: 3.1rem;
            height: 3.1rem;
            margin-right: 2rem;
        }

        &-link {
            font-size: 1.3rem;
            font-weight: 600;
            color: $color-black;
        }

        &-para {
            font-size: 1.2rem;
            font-weight: 500;
            color: $color-black;
        }

    }

}