.mainCab{
    display: flex;
    justify-content: center;
    min-height: 30rem;

    &__container{
      display: flex;
      width: 80%;
      justify-content: center;

      // &--left{
      //   width: 30%;
      // }

      @media (max-width: 50em) { 
        width: 90%;
      }

      &--right{
        width: 80%;
        @media (max-width: 72em) { 
          width: 90%;
        }

        @media (max-width: 62.5em) { 
          width: 95%;
        }
      }
    }
}