.updateRentalForm {
    height: 10.7rem;
    background-color: $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;

    &__container{
        width: 80%;
        display: flex;
        justify-content: center;
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.5rem;
    } 

    &__wrapper-dt {
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.5rem;
        position: relative;
    }

}