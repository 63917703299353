.team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-grey-light-7;
    padding: 4rem 0rem;

    &__container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 5rem;

        @media (max-width: 50em) {
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    &__img-box {
        width: 28%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 50em) {
            width: 80%;
        }
    }

    &__img-1 {
        position: absolute;
        width: 13.7rem;
        height: 13.7rem;
        top: 0;
        right: 0;
    }

    &__img-2 {
        position: relative;
        z-index: 1;
        width: 33rem;
        height: 31rem;

    }

    &__img-3 {
        position: absolute;
        width: 13.7rem;
        height: 13.7rem;
        bottom: 0;
        left: 0;
    }

    &__content {
        width: 63%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media (max-width: 50em) {
            width: 80%;
        }
        


        &-box {
            background-color: $color-white;
            padding: 5rem 6rem;
            border-radius: 5rem 0rem 0rem 5rem;
            width: 90%;

            @media (max-width: 50em) {
                width: 100%;
                border-radius: 3rem;
                margin-top: 2rem;
                padding: 3rem 2rem;
            }
        }

        &-heading {
            font-size: 2.8rem;
            font-weight: 700;
            line-height: 3.4rem;
            color: $color-secondary;
        }

        &-subHeading {
            font-size: 1.8rem;
            font-weight: 700;
            line-height: 4.3rem;
        }

        &-para {
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 2.8rem;
        }

    }


}