.home-main{
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 3rem 4rem;

   @media (max-width: 50em) {
      padding: 3rem 2rem;
   }

   &__box {
      display: flex;

      @media (max-width: 62.5em) {
         flex-direction: column;
      }

      &--left {
         width: 50%;
         display: flex;
         flex-direction: column;
         padding-left: 3rem;

         @media (max-width: 62.5em) {
            width: 100%;
            order: 1;
            align-items: center;
            padding-left: 0rem;
         }

      }

      &--right {
         width: 50%;
         display: flex;
         flex-direction: column;
         align-items: center;
         @media (max-width: 62.5em) {
            width: 100%;
            margin-bottom: 2rem;
         }
      }
   }



   &__img {
      &--pulpit {
         animation: moveToRight 1s ease-out;
         width: 70%;
      }

      &--car {
         margin-right: -2rem;
         z-index: 4;
         animation: drop 1.5s ease-out;
         width: 100%;

         @media (max-width: 62.5em) {
            display: none;
         }
      }
   }

   &__heading {
      font-size: 4rem;
      font-weight: 800;
      color: $color-black;

      &>span {
         color: $color-secondary;
      }

      @media (max-width: 70em) {
         font-size: 3.5rem;
      }
   }

   &__para{
      width: 82%;
      text-align: left;
      padding-left: 1rem;

      @media (max-width: 62.5em) {
         padding-left: 0rem;
         text-align: center;
         font-size: 1.8rem;
         width: 90%;
      }
   }

}