.cabCardBox{
    margin: 1rem;
    width: 100%;

    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__para{
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.4rem; 
        color: $color-black;  
        margin-top: 1rem;
        margin-bottom: 2rem; 
        text-align: center;    
    }


}