.main-aboutus{
    display: flex;
    justify-content: center;
    padding: 4rem 4rem;
    min-height: 60vh;

    &__container{
        width: 90%;
        display: flex;
        
        @media (max-width: 50em) {
            width: 100%;
            flex-direction: column;
            align-items: center;
        }
    }

    &__box1{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 50em) {
            width: 100%;
            align-items: center;
        }
    }

    &__content{
        text-align: left;
        margin-bottom: 2rem;

        @media (max-width: 50em) {
            text-align: center;
        }

        & > p{
            padding-right: 10%;
            margin-top: 1.5rem;
            @media (max-width: 50em) {
                padding-right: 0;
                font-size: 1.8rem;
            }
        }  

        & > h1{
            line-height: 5.7rem;
            margin-bottom: 1.5rem;
        }
    }

    &__box2{
        width: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center; 

        @media (max-width: 50em) {
            width: 100%;
            margin-bottom: 1rem;
        }
    }
 
    &__img{
        width: 80%;
        height: 90%;
        @media (max-width: 50em) {
            width: 100%;
        }
    }

    
}