.partner-form {
    display: flex;
    flex-direction: column;
    width: 75%;
    border-radius: 1rem;
    box-shadow: 0 .4rem .76rem 0rem $color-black-light-3;
    margin-top: -20rem;
    z-index: 2;
    position: relative;
    background-color: $color-white;
    padding: 4rem 3rem;

    @media (max-width: 62.5rem) {
        width: 100%;
    }

    &__heading {
        font-size: 3.6rem;
        font-weight: 700;
        line-height: 2.4rem;
        margin-bottom: 4rem;
        color: $color-black;
    }

    &__para {
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 2.4rem;
        color: $color-black;
        margin-bottom: 1.5rem;
    }

}