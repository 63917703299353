.partner-main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 4rem;

    @media (max-width: 50em) {
        padding: 3rem 2rem;
    }

    &__box {
        display: flex;
        width: 90%;

        @media (max-width: 62.5em) {
            flex-direction: column;
        }

        &--left {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width: 62.5em) {
                width: 100%;
                order: 1;
            }

        }

        &--right {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            @media (max-width: 62.5em) {
                width: 100%;
                margin-bottom: 2rem;
            }
        }
    }

    &__content {
        text-align: right;
        padding-left: 10%;

        @media (max-width: 62.5em) {
            text-align: center;
            padding-left: 0%;
        }
    }

    &__heading {
        font-size: 4rem;
        font-weight: 800;
        line-height: 5.7rem;
        margin: 2rem 0rem;
        color: $color-black;
    }

    &__para {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2.3rem;
        padding-left: 16%;
        margin-bottom: .8rem;

        @media (max-width: 62.5em) {
            padding-left: 0rem;
            font-size: 1.8rem;
        }
    }
}