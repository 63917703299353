.cab-card {
    width: 100%;
    padding: 3rem 2rem 2rem 0rem;
    border: 2px solid $color-grey-light-6;
    box-shadow: 0rem 0rem .7rem 0rem $color-black-light-7;
    display: flex;
    // justify-content: center;
    border-radius: 1rem;
    margin-bottom: 1rem;

    @media (max-width: 50em) { 
        padding: 1rem 2rem;
        margin-bottom: 2rem;
    }

    @media (max-width: 50em) { 
        flex-direction: column;
    }


    &__image-container {
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 50em) { 
            width: 100%;
        }
    }

    &__image-container {
        width: 40% !important;
        @media (max-width: 50em) { 
            width: 100%;
            margin: auto;
            height: 15rem;
        }
    }

    &__image-wrapper {
        width: 80%;
        @media (max-width: 50em) { 
            width: 100%;
        }

    }

    &__image {
        width: 100%;
        height: 100%;
    }

    &__details {
        width: 50%;
        @media (max-width: 50em) { 
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 1.5rem;
        }
    }

    &__details2 {
        width: 60% !important;
        @media (max-width: 50em) { 
            width: 100% !important;
        }
    }

    &__model {
        display: flex;
        align-items: center;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 2.4rem;
        color: $color-black;
        margin-bottom: 1rem;
        
        @media (max-width: 50em) { 
            font-size: 3rem;
        }

        &-span {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 2.4rem;
            color: $color-black;
            margin-left: 1rem;
            @media (max-width: 50em) { 
                font-size: 2rem;
            }
        }
    }

    &__features-list {
        list-style: none;
        display: flex;
        margin-bottom: .5rem;

        @media (max-width: 50em) { 
            margin-bottom: 1.5rem;
            
        }
    }

    &__feature-item {
        text-decoration: none;
        display: flex;
        align-items: center;
        margin-right: 1.5rem;
    }

    &__feature-text {
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 2.4rem;
        color: $color-black;
        @media (max-width: 50em) { 
            font-size: 1.6rem;
        }
    }

    &__icon {
        width: 1.4rem;
        height: 1.2rem;
        margin-right: .6rem;
        @media (max-width: 50em) { 
            width: 1.8rem;
            height: 1.6rem;
        }
    }

    &__car-type {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2.4rem;
        color: $color-primary;
        margin-bottom: .5rem;
        @media (max-width: 50em) { 
           align-self: flex-start;
           font-size: 2rem;
        }
    }

    &__additional-info{
        @media (max-width: 50em) { 
            align-self: flex-start;
            margin-bottom: 1.5rem;
            border-top: 1px solid #939393;
            border-bottom: 1px solid #939393;
            width: 100%;
            padding: 2rem 0rem 3rem 0rem;
        }

    }

    &__additional-item {
        display: flex;
        align-items: center;
    }

    &__info-heading {
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 2.4rem;
        color: $color-black;
        margin-right: .5rem;

        @media (max-width: 50em) { 
            font-size: 1.6rem;
        }

    }

    &__info-text {
        font-size: 1.1rem;
        font-weight: 400;
        line-height: 2.4rem;
        color: $color-black;

        @media (max-width: 50em) { 
            font-size: 1.6rem;
        }
    }

    &__price-section {
        width: 20%;
        @media (max-width: 50em) { 
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__price {
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 2rem;
        color: $color-black;

        @media (max-width: 50em) { 
            font-size: 3rem;
        }
    }

    &__taxes {
        font-size: 1rem;
        font-weight: 400;
        line-height: 2rem;
        color: $color-black;
        margin-bottom: .5rem;
        @media (max-width: 50em) { 
            font-size: 1.2rem;
        }
    }

    &__button {
        background-color: $color-primary;
        color: $color-white;
        border-radius: .8rem;
        border: none;
        outline: none;
        padding: 1rem;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2.4rem;
        cursor: pointer;
        width: 100%;
        @media (max-width: 50em) { 
            width: 60%;
            height: 5rem;
            font-size: 1.6rem;
        }
    }


}