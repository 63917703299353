.statisticSection{
    display: flex;
    justify-content: center;
    padding: 3rem 0rem;
    

    &__container{
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        
        @media (max-width: 50em) {
            width: 100%;
        }

    }

    &__box{
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 56.25em) {
            width: 35%;
            margin: 2rem
        }
    }

    &__icon-box{
        background: linear-gradient(180deg, $color-white 0%, $color-green-light 100%);
        width: 17.6rem;
        height: 15.4rem;
        border-radius: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    &__icon{
       width: 7.64rem;
       height: 6.6rem;
    }

    &__text{
       font-size: 1.6rem;
       font-weight: 600;
       color: $color-black-dark;
    }

    &__num{
       font-size: 2.8rem;
       font-weight: 700;
       color: $color-black-dark;
    }
}