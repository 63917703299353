// color variables
$color-primary : #21A5A0;
$color-primary-light-1: #4FA5A0;
$color-primary-light-2: #21A5A033;
$color-primary-light-3: #EEF7F7;
$color-primary-light-4:#2DB8B3;



$color-secondary: #E05A49;
$color-secondary-light: #e05b491a;
$color-secondary-light-2: #E05A4933;


$color-black:  #1B1B1B;
$color-black-dark: #0F0F0F;
$color-black-light-1: #0000000a;
$color-black-light-2:  #00000080;
$color-black-light-3: #86868640;
$color-black-light-4: #0000000D;
$color-black-light-5: #D5D1D140;
$color-black-light-6: #57575740;
$color-black-light-7: #A6A6A640;



$color-grey: #74757B;
$color-grey-light-1: #B5B4B4;
$color-grey-light-2: #DDDDDD;
$color-grey-light-3: #F0F0F0;
$color-grey-light-4: #F8F8F8;
$color-grey-light-5: #B3B3B3;
$color-grey-light-6: #E4E4E4;
$color-grey-light-7: #F5F5F5;
$color-grey-light-8: #ACACAC;



$color-blue:  #000B33;


$color-background: #DDDDDD;
$color-white: #ffff;

$color-green-light: #C8EEED;



$line: 1px solid #AEAEAE;
// grid width

// font size
