.book-cab-main {
  display: flex;
  padding: 2rem 2rem;
  justify-content: center;
  width: 100%;

  &__container {
    display: flex;
    width: 90%;

    @media (max-width: 50em) {
      flex-direction: column;
    }
  }

  &__content {
    width: 65%;

    @media (max-width: 50em) {
      width: 100%;
    }
  }

  &__payment-section {
    width: 35%;
    padding: 0rem 2rem;
    @media (max-width: 50em) {
      width: 100%;
      padding: 0rem 0rem;
    }
  }
}