/*
  0 - 600px phone
  600px - 900px tab-portrait
  900px - 1300px tab-landscape
  1300px - 1800px normal laptop
  1800+ - for big screen

  $breakpoint argument choices:
  - phone
  - tab-port
  - tab-land
  - big-desktop

  Order: base -> typography -> layouts -> pages layout -> component
*/

// 1em = 16px
// The em unit in media queries does not depend on the font size of the parent elements within the document structure; it always refers to the root font size.

@mixin response($breakpoint){
    @if $breakpoint == phone {
        @media (max-width: 37.5em) {
            @content
        }; // 600px
    }

    @if $breakpoint == tab-port {
        @media (max-width: 56.25em) {
            @content
        }; // 900
    }

    @if $breakpoint == tab-land {
        @media (max-width: 81.25em) {
            @content
        }; // 1300px
    }

    @if $breakpoint == big-desktop-small {
        @media (min-width: 90.5em) {
            @content
        }; // 1800+
    }

    @if $breakpoint == big-desktop-small-2 {
        @media (min-width: 100.5em) {
            @content
        }; // 1800+
    }

    @if $breakpoint == big-desktop {
        @media (min-width: 112.5em) {
            @content
        }; // 1800+
    }

    @if $breakpoint == big-desktop-2 {
        @media (min-width: 120.5em) {
            @content
        }; // 1800+
    }

    @if $breakpoint == big-desktop-3 {
        @media (min-width: 135.5em) {
            @content
        }; // 1800+
    }

    @if $breakpoint == big-desktop-4 {
        @media (min-width: 175.5em) {
            @content
        }; // 1800+
    }
}