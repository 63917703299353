.updateOutstationForm {
    height: 10.7rem;
    background-color: $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;

    &__container--one {
        width: 80%;
        display: flex;
    }

    &__container--round {
        width: 90%;
        display: flex;
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.5rem;
    }

    &__wrapper-dt {
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.5rem;
        position: relative;
    }
}

