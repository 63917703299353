.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; // Full-screen loading

    &__spinner {
        width: 50px;
        height: 50px;
        border: 6px solid rgba(0, 0, 0, 0.1);
        border-top: 6px solid #3498db; // Primary color for the spinner
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    &__text {
        margin-top: 15px;
        font-size: 1.2rem;
        color: #555;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}