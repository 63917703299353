.business-benefit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2rem;
        border: 1px solid $color-grey-light-6;
        box-shadow: -.1rem .2rem 1rem 0rem $color-black-light-5;
        width: 42%;
        padding: 1rem;
        min-height: 22rem;
        border-radius: 1rem;
        overflow: hidden;

        @media (max-width: 62.5rem) {
            margin: 1rem;
            width: 45%;
        }
    }

    &__icon-box {
        margin-bottom: 1rem;
    }

    &__icon {
        width: 6rem;
        height: 6rem;
        fill: $color-secondary;
    }

    &__detail {

        &-heading {
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 2.3rem;
            color: $color-black;
            text-align: center;
        }

        &-para {
            font-family: Montserrat;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 2.3rem;
            color: $color-black;
            text-align: center;
        }
    }
}