.downloadSection{

    width: 100%;
    height: 39.9rem;
    background-image: url(../../../public/images/download-bg.png);
    background-size: cover;
    background-position: top;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: 40em) {
        background-image: url(../../../public/images/download-bg-small.png);
    }

    &__img{
        position: absolute;
        width: 11.6rem;
        height: 11.6rem;

        &--1{
          top: 0;
          left: 8%;
        }

        &--2{
           right: 0;
           top: 25%;
        }

    }

    &__quote{
        font-size: 4.8rem;
        font-weight: 700;
        line-height: 5.76rem; 
        color: $color-white; 
        margin-bottom: 1rem;  
        text-align: center;

        @media (max-width: 50em) {
            width: 80%;
        }
    }

    &__description{
        display: inline-block;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.6rem;
        color: $color-white;
        width: 45%;
        text-align: center;
        margin-bottom: 3rem;
        @media (max-width: 50em) {
            width: 80%;
        }
    }

    &__buttons{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__button{
        width: 17rem;
        height: 5.4rem;
        background-color: $color-black-dark;
        border-radius: 1rem;
        border: none;
        box-shadow: 0rem .3rem 1rem rgba($color-black-dark, 0.6);


        &:not(:last-child){
            margin-right: 2rem;
        }

        @media (max-width: 50em) {
            width: 18rem;
            height: 5.8rem;
        }
    }

    &__link{
        display: flex;
        text-decoration: none;
        justify-content: space-around;
        // padding: 1rem 1rem;
        
    }

    &__icon{
        width: 3.5rem;
        height: 3.5rem;
        fill: $color-white;
        // margin-left: 1rem;
    }

    &__text{
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        line-height: 1.8rem;
        
    }

    
    &__text-secondary{
        font-size: 1.6rem;
    }

}