// PulpitPrivacyPolicies.scss

.privacy-policies {
    padding: 4rem;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: $color-primary;
  
    h1 {
      text-align: center;
      color: $color-primary;
      font-size: 3rem;
      margin-bottom: 1.5rem;
    }
  
    section {
      margin-bottom: 2rem;
  
      h2 {
        font-size: 2rem;
        color:$color-primary;
        margin-bottom: 0.8rem;
      }
  
      h3 {
        font-size: 1.7rem;
        color: $color-secondary;
        margin-bottom: 0.6rem;
      }
  
      p {
        margin-bottom: 1.4rem;
        font-size: 1.6rem;
        color: $color-black-dark;
      }
  
      ul {
        margin-left: 1.5rem;
        list-style-type: disc;
        color: $color-black-dark;
        li {
          margin-bottom: 0.5rem;
          font-size: 1.6rem;
        }
      }
    }
  }  