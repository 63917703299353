.main-blogs {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 4rem;

    &__heading{
      color: $color-secondary;
    }

    &__blogs-container {
        width: 100%;
        margin: 2rem 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    &__button {
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 2.4rem;
        padding: 1rem 3rem;
        background-color: $color-primary;
        border-radius: .8rem;
        box-shadow: 0rem .7rem 1rem rgba($color-black, 0.2);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        transition: all .1s;

        &:active{
            transform: translateY(1px);
        }
    }

    &__icon {
        fill: white;
        width: 1.6rem;
        height: 1rem;
        margin-left: 1rem;
    }
}