.blog-card2 {
    display: flex;
    flex-direction: column;
    // border-radius: 1rem 1rem 0rem 0rem;
    border: 1px solid #EEEEEE;
    margin: 1rem;
    width: 36.2rem;
    overflow: none;
    cursor: pointer;
    text-decoration: none;
    color: inherit;

    &__image-container {
        width: 36rem;
        height: 27rem;
    }

    &__image {
        width: 100%;
        height: 100%;
        // border-radius: 1rem 1rem 0rem 0rem;
        object-fit: cover;
        object-position: top;
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem 1rem;
    }

    &__topic {
        font-size: 2rem;
        font-weight: 800;
        line-height: 2.6rem;
        color: $color-black;
        margin-bottom: 1rem;
        height: 5.2rem;
        overflow: hidden;

        &-title {
            color: $color-secondary;
        }
    }

    &__details {
        display: flex;
        margin-bottom: 1rem;
        color: $color-grey-light-8;
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 2.4rem;
    }

    &__author {
        color: $color-black;
        margin-right: 2rem;
    }


    &__description {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 2.4rem;
        height: 7.2rem;
        overflow: hidden;
    }
}