.navbar {
    background-color: $color-white;
    height: 8.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;

    @media (max-width: 50em) {
        height: 9rem;
    }

    &__box {
        width: 90%;
        height: 7rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 72em) {
            justify-content: space-between;
        }

        @media (max-width: 50em) {
            width: 95%;
        }

    }


    &__logo {
        width: 13.6rem;
        height: 4.8rem;
        margin-right: 1rem;
    }

    &__menu {
        list-style: none;
        display: flex;
        align-items: center;

        @media (max-width: 72em) {
            display: none;
        }

        &-item {
            margin: .9rem 2rem;
            position: relative;
            padding-bottom: .2rem;

        }

        &-link {
            color: $color-black-dark;
            text-decoration: none;
            font-weight: 500;

            &:hover {
                color: $color-secondary;
            }

            &::after {
                content: "";
                height: .2rem;
                position: absolute;
                bottom: 1px;
                left: 0;
            }

            &:hover::after {
                width: 100%;
                background-color: $color-secondary;
                animation: borderAnimation .2s ease-out;
            }

        }

        &__active{
            color: $color-secondary;
        }

    }

    &__mobile{
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba($color-primary,0.95);
        display: flex;
        justify-content: center;
        z-index: 3;
        &-menu {
            position: relative;
            top: -4rem;
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 4;
            animation: moveToRight 1s;
    
            &-item {
                margin: .9rem 2rem;
                position: relative;
                padding-bottom: .2rem;
    
            }
    
            &-link {
                color: $color-black-dark;
                text-decoration: none;
                font-weight: 500;
                font-size: 4rem;
    
                &:hover {
                    color: $color-secondary;
                }
    
                &::after {
                    content: "";
                    height: .2rem;
                    position: absolute;
                    bottom: 1px;
                    left: 0;
                }
    
                &:hover::after {
                    width: 100%;
                    background-color: $color-secondary;
                    animation: borderAnimation .2s ease-out;
                }
    
            }
    
            &__active{
                color: $color-secondary;
            }
    
        }
       
        &-action{
            &-link {
                display: flex;
                align-items: center;
                text-decoration: none;
                color: $color-black-dark;
    
            }
        }

        &-profile {
            // text-decoration: none;
    
            &-pic {
                width: 8rem;
                height: 8rem;
                border-radius: 50%;
                border: 2px solid #E05A49;
                object-fit: cover;
            }
    
            &-name {
                font-weight: 600;
                margin-left: 1rem;
                font-size: 2.5rem;
            }
    
        }

        &-login{
            background-color: $color-secondary;
            border-radius: 0.6rem;
            padding: .8rem .9rem;
            box-shadow: 0rem .2rem .3rem rgba($color-black-dark, 0.5);
            transition: all .1s;
            width: 15rem;
            height: 6rem;
    
            &-icon {
                width: 3rem;
                height: 3rem;
                fill: $color-white;
                margin-right: .6rem;
            }
    
            &-text {
                color: $color-white;
                margin-left: .5rem;
                font-size: 3rem;
            }
    
            &:active {
                transform: translateY(.1rem);
            }
    
        }
    }
    



    &__action {
        display: flex;
        list-style: none;
        align-items: center;


        &-item {
            padding: .9rem 1rem;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width: 72em) {
                &:first-child {
                    border-left: $line;
                    padding-left: 2rem;
                }
            }
        }

        &-link {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: $color-black-dark;

        }

    }

    &__app {
        color: $color-secondary;
        box-shadow: 0rem .2rem .3rem rgba($color-black-dark, 0.5);
        border-radius: .5rem;
        padding: .8rem .8rem;

        &-icon {
            width: 2.2rem;
            height: 2.2rem;
            fill: currentColor;
        }

        &-text {
            margin-left: .6rem;
            color: currentColor;
            font-weight: 600;
            letter-spacing: 0.25px;
        }

        &:hover {
            color: rgba($color-secondary, 0.8);
        }

        &:active {
            transform: translateY(.1rem);
        }

    }

    &__contact {
        background-color: $color-primary;
        border-radius: 0.6rem;
        padding: .8rem .5rem;
        box-shadow: 0rem .2rem .3rem rgba($color-black-dark, 0.5);
        transition: all .1s;

        @media (max-width: 72em) {
            padding: .8rem 1rem;
        }

        &-icon {
            width: 1.5rem;
            height: 1.5rem;
            fill: $color-white;
            @media (max-width: 72em) {
                width: 2.5rem;
                height: 2.5rem;
            }
        }

        &-text {
            color: $color-white;
            margin-left: .5rem;

            @media (max-width: 72em) {
                display: none;
            }
        }

        &:active {
            transform: translateY(.1rem);
        }

    }

    &__profile {
        // text-decoration: none;

        &-pic {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            border: 2px solid #E05A49;
            object-fit: cover;
        }

        &-name {
            font-weight: 600;
            margin-left: 1rem;
        }

        &-icon {
            width: 1.1rem;
            height: 1.1rem;
            margin-left: .6rem;
            margin-top: .2rem;
            fill: rgba($color-black-dark, .7);
        }

        @media (max-width: 72em) {
            display: none;
        }
    }

    &__login{
        background-color: $color-secondary;
        border-radius: 0.6rem;
        padding: .8rem .9rem;
        box-shadow: 0rem .2rem .3rem rgba($color-black-dark, 0.5);
        transition: all .1s;

        &-icon {
            width: 1.5rem;
            height: 1.5rem;
            fill: $color-white;
            @media (max-width: 72em) {
                width: 2.5rem;
                height: 2.5rem;
            }
        }

        &-text {
            color: $color-white;
            margin-left: .5rem;

            @media (max-width: 72em) {
                display: none;
            }
        }

        &:active {
            transform: translateY(.1rem);
        }

        @media (max-width: 72em) {
            display: none;
        }
    }

    &__icon {
        width: 4rem;
        height: 4rem;
        display: none;
        fill: $color-secondary;
        z-index: 4;

        @media (max-width: 72em) {
            display: inline-block;
        }
    }


}