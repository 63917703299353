.teamCard {
    width: 28rem;
    height: 39.4rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 2rem;
    background-color: $color-white;
    border: 1px solid #C4C4C4;


    &__img-box {
        display: inline-block;
        width: 28rem;
        height: 70%;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 2rem 2rem 0rem 0rem;
        margin-top: -1px;
    }

    &__heading {
        font-size: 2rem;
        font-weight: 800;
        line-height: 5.76rem;
        color: $color-primary;
    }

    &__para {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 3.24rem;
        color: $color-blue;
    }
}