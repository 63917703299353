.primary__heading{
    font-size: 4rem;
    font-weight: 800;
    color: $color-black-dark;
 }
 
 .primary__heading--white{
    font-size: 4rem;
    font-weight: 800;
    color: $color-white;
 }

 .secondary__heading{
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 3rem;
 }

 .paragraph{
      font-size: 1.4rem;
      font-weight: 400;
      color: $color-black-dark;
      
 }