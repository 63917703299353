.benefit-getApp {
    display: flex;
    flex-direction: column;
    padding: 3rem 3rem;
    background-color: $color-primary;
    align-items: center;
    min-height: 40rem;

    @media (max-width: 56.25em) {
        padding: 3rem 2rem;
    }

    &__container {
        width: 90%;
        margin-top: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        
        @media (max-width: 56.25em) {
            width: 100%;
            margin-top: 3rem;
        }
    }

    &__box {
        width: 20%;
        margin: 2rem;

        @media (max-width: 56.25em) {
            width: 40%;
        }
        
    }

    &__icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50%;
        margin-bottom: 2rem;
    }

    &__icon {
        width: 6rem;
        height: 6rem;
    }

    &__title {
        font-size: 2.2rem;
        font-weight: 700;
        line-height: 2.6rem;
        color: $color-white;
        margin-bottom: 1rem;
        text-align: center;
    }

    &__description {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.8rem;
        text-align: center;
        color: $color-white;
    }
}