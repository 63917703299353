.main-getApp{
    background-image: url(../../../../public/images/getApp-main-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 60rem;
    display: flex;
    justify-content: center;
    padding: 5rem 0rem;

    &__container{
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--left{
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 56.25em) {
        width: 100%;
        align-items: center;
      }
    }

    &--right{
        width: 50%;
        @media (max-width: 56.25em) {
            display: none;
        }
    }

    &__heading{
        font-size: 6.4rem;
        font-weight: 700;
        line-height: 6.9rem;
        text-align: left;  
        color: $color-white; 
        margin-bottom: 2rem;     

        @media (max-width: 62.5em) {
            font-size: 5rem;
            line-height: 5.4rem;
        }

        @media (max-width: 56.25em) {
            text-align: center;
        }
    }

    &__para{
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 3rem;
        text-align: left; 
        color: $color-white;
        
        @media (max-width: 56.25em) {
            text-align: center;
        }
    }
}