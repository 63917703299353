.outstation-form {

    width: 87%;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;

    &__option-box {
        display: flex;
        margin-bottom: 2rem;
    }

    &__option {
        height: 3.4rem;
        border-radius: 10rem;
        padding: .5rem 2rem;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-black;
        position: relative;

        &:not(:last-child){
            margin-right: 1rem;
        }
    }

    &__option-active {
        background-color: $color-secondary-light-2;
    }

    &__date-time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
    }

    &__paragraph {
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 3.2rem;
        text-align: center;
        color: $color-black;
        margin: 1.5rem 0rem;  
    } 

    

}

// Hide the default placeholder text when input is empty
.input-date::-webkit-datetime-edit,
.input-time::-webkit-datetime-edit {
    color: transparent;
}

// Show text when input is focused or has a value
.input-date:valid::-webkit-datetime-edit,
.input-time:valid::-webkit-datetime-edit {
    color: $color-black; // Use your text color variable
}

@media (max-width: 900px) {
    

    .input-wrapper .input-icon {
        display: none;
    }
    // Maintain the same behavior on mobile
    .input-date::-webkit-datetime-edit,
    .input-time::-webkit-datetime-edit {
        color: transparent;
    }
    
    .input-date:valid::-webkit-datetime-edit,
    
    .input-time:valid::-webkit-datetime-edit {
        color: $color-black;
    }
    
}

.input-wrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow: hidden;

  
    .input-placeholder {
      position: absolute;
      top: 50%;
      left: 1rem;
      transform: translateY(-80%);
      font-size: 1.4rem;
      color: #000000;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 90%;  // Prevent text from overflowing container
    }
  }
  