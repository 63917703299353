.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    border-radius: 1rem;
    box-shadow: 0 .4rem .76rem 0rem $color-black-light-3;
    margin-top:-18rem;
    z-index: 2;
    position: relative;
    background-color: $color-white;
    

    @media (max-width: 62.5rem) {
        width: 94%;
    }
    
    
    &__options{
        display: flex;
        justify-content: space-around;
        width: 90%;

    }

    &__option--active{
        width: 30%;
        height: 11.3rem;
        border-radius: 0rem 0rem .8rem .8rem;
        background-color: $color-primary;
        box-shadow: 2rem 2.4rem 9.4rem 0rem $color-black-light-4;
        color: $color-white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 700;
     }
    
    &__option{

        width: 30%;
        height: 11.3rem;
        border-radius: 0rem 0rem .8rem .8rem;
        color: $color-black-dark;
        box-shadow: 2rem 2.4rem 9.4rem 0rem $color-black-light-4;
        background-color:  $color-grey-light-3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        cursor: pointer;

        &:hover{
            background-color: rgba($color-grey-light-3,0.7);
            color: rgba($color-black-dark,0.8);
        }


        &-icon{
           width: 3.9rem;
           height: 3.9rem;
           fill: currentColor;
           margin-bottom: .6rem;
        }

        &-text{
           color: currentColor;
           font-size: 1.6rem;
           font-weight: inherit;
        }

        &:active{
            transform: translateY(.3rem);
        }

    }

}

