.header{
    height: 23.8rem;
    position: relative;
    overflow: hidden;
    box-shadow: 0rem 1rem 2rem rgba($color-black,0.4);
    
    &__image{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top right;
        
    }

    &::before{
        content:"";
        position:absolute;
        top:0;
        left:0;
        width: 100%;
        height:100%;
        background: linear-gradient(to right bottom, rgba($color-black,0.2),rgba($color-black,0.7));
    }
}