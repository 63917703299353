.business-main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 4rem;

    @media (max-width: 50em) {
        padding: 3rem 2rem;
    }

    &__box {
        display: flex;
        width: 90%;

        @media (max-width: 67.37em) {
            width: 95%;
            flex-direction: column;
        }

        &--left {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width: 67.37em) {
                width: 100%;
                order: 1;
            }

        }

        &--right {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            @media (max-width: 67.37em) {
                width: 100%;
                margin-bottom: 2rem;
                align-items: center;
            }
        }
    }

    &__img {
       width: 100%;
       height: 100%;
       border-radius: 2rem;
       object-fit: cover;

    }

    &__img-box{
        width: 48.8rem;
        height: 40rem;
        border-radius: 2rem 0rem 0rem 0rem;
        margin-top: 2rem;
        
        @media (max-width: 67.37em) {
           display: none;
        }
    }
}