.book-cab-form{
    padding: 2rem;
    border: 1px solid #E3E3E3;
    box-shadow: 0px 0px 7.7px 0px #A6A6A640;
    border-radius: 1rem;
    width: 100%;
    margin-top: 4rem;

    @media (max-width: 50em) {
        margin-bottom: 2rem;
    }

    &__heading{
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 2.4rem;
        text-align: left;
        color: $color-black;
        margin-bottom: 2rem;
    }

    &__box{
        display: flex;
        &:not(:last-child){
            margin-bottom: 1.5rem;
        }
    }

    &__wrapper{
        width: 45%;
        &:not(:last-child){
            margin-right: 2%;
        }

        @media (max-width: 50em) {
            width: 100%;
        }
    }
    

}