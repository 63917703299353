.client {
    background-color: $color-grey-light-4;
    padding: 3rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__option-box {
        display: flex;
        border: 1px solid #AEAEAE;
        border-radius: .7rem;
        margin-bottom: 3rem;
        width: 60%;

        @media (max-width: 56.25em) {
            width: 70%;
        }

        @media (max-width: 46.87em) {
            width: auto;
        }

    }

    &__option {
        font-size: 2rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-black;
        border-radius: .7rem;
        padding: .5rem 1rem;
        width: 50%;
        cursor: pointer;

        @media (max-width: 46.87em) {
            padding: 1rem 1.5rem;
            font-size: 1.6rem;
            width: auto;
            
        }

    }

    &__option-active {
        font-weight: 700;
        background-color: $color-secondary;
        color: $color-white !important;
    }

    &__container{
        width: 70%;
        display: flex;
        justify-content: center;

        @media (max-width: 50em) {
            width: 95%;
        }
    }
}