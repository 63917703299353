// Variables
$overlay-bg: rgba(0, 0, 0, 0.5);
$backdrop-blur: 4px;
$dialog-bg: #fff;
$dialog-size: 350px; // Increased from 300px to 350px for a larger square
$dialog-border-radius: 10px;
$dialog-padding: 20px;
$z-index-overlay: 1000;
$z-index-dialog: 1001;
$checkmark-color: #21A5A0;
$checkmark-stroke-width: 5;

// Dialog Overlay
.dialog__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $overlay-bg;
  backdrop-filter: blur($backdrop-blur);
  -webkit-backdrop-filter: blur($backdrop-blur);
  z-index: $z-index-overlay;
}

// Dialog Content (Centered, Square)
.dialog__content {
  position: fixed;
  top: 50%;
  left: 50%;
  width: $dialog-size;
  height: $dialog-size;
  padding: $dialog-padding;
  background-color: $dialog-bg;
  border-radius: $dialog-border-radius;
  transform: translate(-50%, -50%);
  z-index: $z-index-dialog;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  // Use the same font as other elements (assuming your base font is set for the page)
  font-family: inherit;
}

// Dialog Title and Description (Bigger & matching font)
.dialog__title {
  font-size: 2.4rem; // increased from 1.5rem
  margin-bottom: 1rem;
  font-weight: 700;
}

.dialog__description {
  font-size: 1.6rem; // increased from 1rem
  margin-bottom: 1.5rem;
  font-weight: 500;
}

// Animated Checkmark Styles (Bigger icon)
.dialog__icon-container {
  margin-bottom: 1rem;
}

.dialog__icon {
  width: 80px; // increased from 60px
  height: 80px; // increased from 60px
}

.dialog__icon-circle {
  stroke: $checkmark-color;
  stroke-width: $checkmark-stroke-width;
  fill: none;
}

.dialog__icon-check {
  stroke: $checkmark-color;
  stroke-width: $checkmark-stroke-width;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: drawCheck 0.8s ease-out forwards;
}

// Checkmark animation
@keyframes drawCheck {
  to {
    stroke-dashoffset: 0;
  }
}

// Dialog Button
.dialog__button {
  margin-top: 1.5rem;
  height: auto;
  width: 100%;
  padding: 1.5rem 0; // Increase padding for better touch experience
  font-size: 2.4rem; // Increase font size
  font-weight: 700;
  text-align: center;
  background-color: $checkmark-color;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: darken($checkmark-color, 10%);
    color: white;
  }

  @media (max-width: 480px) {
    font-size: 2rem; // Slightly smaller on small screens
    padding: 1.2rem 0;
  }
}





.payment-card {
    border: 1px solid #E3E3E3;
    box-shadow: 0px 0px 7.7px 0px #A6A6A640;
    width: 100%;
    padding: 2rem;
    border-radius: 1rem;

    &__heading {
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 2.4rem;
        color: $color-black;
        margin-bottom: 2rem;
    }


    &__options {
        display: flex;
        flex-direction: column;
    }

    &__label {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid #E3E3E3;
        padding: 1rem 0rem;
    }

    &-radio {
        width: 10%;
    }

    &__para {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.8rem;
        color: $color-black;
        display: flex;
        flex-direction: column;

        &>span {
            font-size: 1.2rem;
            font-weight: 500;
        }

        width: 70%;
    }

    &__amount {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.4rem;
        color: $color-black;
        width: 20%;
        text-align: right;
    }

    &__coupon {
        display: flex;
        width: 100%;
        position: relative;
        margin-top: 1rem;
        margin-bottom: 1rem;
        &-input {
            width: 80%;
            height: 5rem;
            border: 1px solid $color-black;
            border-radius: .6rem 0rem 0rem .6rem;
            padding: 1rem;
            outline: none;
            border-right: none;
        }

        &-button {
            position: absolute;
            top: 0;
            height: 5rem;
            right: 0;
            width: 20%;
            background-color: $color-primary;
            color: white;
            font-size: 2rem;
            border: 1px solid $color-black;
            border-radius: 0rem .6rem .6rem 0rem;
            border-left: none;
            cursor: pointer;
        }

        &-remove{
            background-color: $color-primary;
            color: $color-white;
            border: none;
            outline: none;
            cursor: pointer;
            font-size: 1.4rem;
            padding: 1rem .5rem;
            border-radius: .8rem;
            overflow: hidden;
            width: 15rem;
        }

    }

    &__coupon-applied {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        padding: 0rem .5rem;
 
    }

    &__offer {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        padding: 1rem .5rem;
        border-bottom: 1px solid #E3E3E3;
        justify-content: space-between;
    }

    &__amount2 {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.4rem;
        color: $color-black;
        width: 20%;
        text-align: right;
    }

    &__total {
        display: flex;
        align-items: center;
        padding: 1rem .5rem;
        justify-content: space-between;
    }

    &__total--sub {
        display: flex;
        align-items: center;
        padding: .4rem .5rem;
        justify-content: space-between;
    }

    &__pay-button {
        width: 100%;
        border: none;
        background-color: $color-primary;
        color: $color-white;
        height: 5rem;
        margin-top: 1rem;
        border-radius: 1rem;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 2.4rem;
        cursor: pointer;

        &:hover{
            background-color: rgba($color-primary,0.9);
        }
    }
    &__dialog {
        z-index: 9999;
    }
}