.videoSection{
    display: flex;
    justify-content: center;
    padding: 3rem 0rem;
    min-height: 60vh;



    &__container{
        width: 80%;
        display: flex;
        
        @media (max-width: 50em) {
            flex-direction: column;
            align-items: center;
        }

    }

    &__box1{
        width: 40%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center; 
        background-image: url(../../../../public/images/video-background.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 30.8rem;

        @media (max-width: 50em) {
            width: 100%;

        }
    }
 
     &__iframe{
        width: 70%;
        height: 60%; 
        z-index: 2;
        border-radius: 1rem;
        
     }

    &__box2{
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 50em) {
            width: 100%;
            align-items: center;
        }
    }

    &__content{
        text-align: right;

        @media (max-width: 50em) {
            text-align: center;
        }

        & > p{
            padding-left: 18%;
            margin-top: 1.5rem;
            @media (max-width: 50em) {
                padding-left: 0rem;
                font-size: 1.8rem;
            }
        }  

        & > h1{
            line-height: 5.7rem;
            margin-bottom: 1.5rem;
        }
    }
   

    

}