.faq {
    width: 80%;
    margin: 2rem auto;
    padding: 1rem;

    @media (max-width: 50em) {
        width: 90%;
    }


    &__heading {
        font-size: 3rem;
        font-family: 700;
        color: $color-black;
        margin-bottom: 1.5rem;

        @media (max-width: 50em) {
            font-size: 2.5rem;
        }
    }

    &__item {
        border-bottom: 1px solid #ddd;
        padding: 1rem 0;

        &:last-child {
            border-bottom: none;
        }
    }

    &__question {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        font-weight: bold;
        font-size: 1.8rem;
        color: $color-black;

        &:hover {
            color: $color-secondary;
        }
    }

    &__icon {
        font-size: 2rem;
        transition: transform 0.3s;
        margin-left: 1rem

        &--active {
            transform: rotate(180deg);
            margin-left: .5rem
        }
    }

    &__answer {
        padding: 0.5rem 0;
        font-size: 1.6rem;
        color: $color-grey;
    }
}