.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    margin: auto;
    max-width: 100%;
  }
  
  .slider-wrapper {
    overflow: hidden;
    width: 82%; /* Card width times the number of visible cards plus margins */
    margin-bottom: 2rem;
    background-color: transparent;
  }
  
  .slider-cards {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .slider__buttons{
    display: flex;
    justify-content: center;
  }
  
  .prev-button,
  .next-button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    margin: 1rem;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  
  .prev-icon{
    width: 4.15rem;
    height: 4.15rem;
  }
  
  .next-icon{
    width: 4.15rem;
    height: 4.15rem;  
  }
  
  .prev-button:disabled,
  .next-button:disabled {
    cursor: not-allowed;
  }