.footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__upper {
        width: 90%;
        display: flex;
        padding-top: 5rem;
        padding-bottom: 2rem;
        flex-wrap: wrap;

    }

    &__column {
        display: flex;
        flex-direction: column;
        align-items: left;

        &--logo {
            width: 27%;
            @media (max-width: 62.5rem) {
                width: 70%;
                border-bottom: 1px solid $color-grey-light-5;
                margin-bottom:2rem;
            }
        }

        &--links {
            width: 19%;
            @media (max-width: 62.5rem) {
                width: 30%;
                border-bottom: 1px solid $color-grey-light-5;
                margin-bottom:2rem;
            }
        }

        &--contact {
            width: 27%;
            @media (max-width: 62.5rem) {
                width: 100%;
                border-bottom: 1px solid $color-grey-light-5;
                margin-bottom:2rem;
            }
        }

        &--newsletter {
            width: 27%;
            @media (max-width: 62.5rem) {
                width: 100%;
                margin-bottom:2rem;
            }
        }
    }

    &__logo {
        width: 13.6rem;
        margin-bottom: 1.5rem;
    }

    &__download-button {
        margin-bottom: 3rem;
    }

    &__heading-primary {
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 3.4rem;
        margin-bottom: 3rem;
        color: $color-black-dark;

        @media (max-width: 62.5rem) {
            font-size: 2rem;
        }
    }

    &__heading-secondary {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.7rem;
        margin-bottom: .5rem;
        color: $color-black-dark;
    }

    &__text {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.6rem;
        margin-bottom: 1rem;
    }

    &__social-list {
        display: flex;
        list-style: none;
    }

    &__social-item {
        width: 4rem;
        height: 4rem;
        background-color: $color-primary;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
    }

    &__social-icon {
        fill: $color-white;
        width: 2rem;
        height: 2rem;
    }


    &__links-list {
        display: flex;
        flex-direction: column;
        align-items: left;
        list-style: none;
    }

    &__links-item {
        margin-bottom: 1rem;
    }

    &__page-link {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.6rem;
        margin-bottom: 2rem;
        color: $color-black-dark;
        text-decoration: none;

        &:hover {
            color: $color-secondary;
        }

    }

    &__contact-list {
        list-style: none;
        @media (max-width: 62.5rem) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__contact-item {
        margin-bottom: 1rem;
    }

    &__contact-box {
        display: flex;
    }

    &__contact-iconbg {
        background-color: $color-primary;
        width: 2.6rem;
        height: 2.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        flex-shrink: 0;
    }

    &__contact-icon {
        width: 1rem;
        height: 1rem;
        fill: $color-white;
    }

    &__newsletter-text {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.6rem;
        margin-bottom: 2rem;
        text-align: left;
    }

    &__lower {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 6rem;
        border-top: 1px solid $color-grey-light-5;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 2.4rem;
        letter-spacing: 0.75px;
        align-items: center;
        
        @media (max-width: 62.5rem) {
            flex-direction: column;
        }
        
    }

    &__lower-text {
        margin-left: 5%;
        color: $color-black-dark;
        @media (max-width: 62.5rem) {
            border-top: 1px solid $color-grey-light-5;
            margin-left: 0;
            padding: 2rem 0rem;
            order:2;
        }
    }

    &__lower-links {
        margin-right: 5%;
        @media (max-width: 62.5rem) {
            margin-right: 0;
            padding: 2rem 0rem;
            order:1;
        }
    }

    &__lower-link {
        text-decoration: none;
        color: $color-black-dark;
    }

    &__subscribe-button{
        border-radius: 0rem;
    }
}