.blogPage{
    &__main{
        display: flex;
        width: 90%;
        margin: 3rem auto;
    }

    &__box--left{
        width: 65%;
        margin-right: 3rem;

        @media (max-width: 50em) {
            width: 100%;
        }
    }

    &__box--right{
        width: 30%;
        background-color: #f7f7f7;
        padding: 2rem;
        max-height: 100rem;
        @media (max-width: 50em) {
            display: none;
        }
    }

    &__recent-heading{
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        color: $color-black;
    }
}