.about-app {
    background-image: url(../../../../public/images/women.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 30.8rem;
    display: flex;
    justify-content: flex-end;
    padding: 5rem 3rem;

    @media (max-width: 56.25em) {
        background-image: url(../../../../public/images/women-small.png);
    }
    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 15rem;
        @media (max-width: 62.5em) {
            margin-right: 0rem;
        }
    }

    &__heading {
        font-size: 4rem;
        font-weight: 800;
        line-height: 5.7rem;
        color: $color-primary;
        margin-bottom: 2rem;
    }

    &__list {
        display: flex;
        flex-direction: column;
        list-style: none;
        &-item {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: $color-white;
            font-family: Montserrat;
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 4.3rem;
        }
    }

    &__icon {
        width: 2.6rem;
        height: 2.6rem;
        margin-right: 2rem;
    }

    &__button {
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.4rem;
        text-align: center;
        background-color: $color-secondary;
        border-radius: .8rem;
        padding: 1.6rem 3.2rem;
        border: none;
        color: $color-white;
        margin-top: 2rem;
        cursor: pointer;
        transition: all .1s;
        
        &:active{
            transform: translateY(1px);
        }
    }

}