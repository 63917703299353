@import  "./sass/abstracts/variables";
@import "./sass/base/animations";
@import "./sass/base/utilities";
@import  "./sass/abstracts/mixins";
@import  "./sass/abstracts/functions";
@import "./sass/base/base";
@import "./sass/base/typography";
@import "./layout/Navbar/navbar";
@import "./layout/Header/header";
@import "./screens/Home/home";
@import "./screens/Home/Main/main";
@import "./screens/Home/Main/Form/Form";
@import "./screens/Home/ServiceSection/serviceSection";
@import "./screens/Home/VideoSection/videosection";
@import "./screens/Home/StatisticSection/statisticSection";
@import "./sass/components/blogCard";
@import "./screens/Home/BlogSection/BlogSection";
@import "./layout/Client/client";
@import "./sass/components/clientCard";
@import "./sass/components/reviewCard";
@import "./sass/components/slider";
@import "./layout/Review/review";
@import "./layout/downloadSection/downloadSection";
@import "./sass/components/serviceCard";
@import "./sass/components/input";
@import "./screens/Home/Main/Form/OutstationForm/outstationForm";
@import "./screens/Home/Main/Form/RentalForm/rentalForm";
@import "./screens/Home/Main/Form/LocalForm/localForm";
@import "./sass/components/starRating";
@import "./layout/Footer/footer";
@import "./screens/Cab/UpdateCabForm/updateCabForm";
@import "./screens/Cab/UpdateCabForm/UpdateOutstationForm/updateOutstationForm";
@import "./screens/Cab/UpdateCabForm/UpdateLocalForm/updateLocalForm";
@import "./screens/Cab/UpdateCabForm/UpdateRentalForm/updateRentalForm";
@import "./screens/Partner/MainPartner/mainPartner";
@import "./screens/Partner/MainPartner/PartnerBenefit/partnerBenefit";
@import "./screens/Partner/MainPartner/PartnerForm/partnerForm";
@import "./screens/Partner/EarnPartner/earnPartner";
@import "./screens/Business/MainBusiness/mainBusiness";
@import "./screens/Business/BusinessForm/businessForm";
@import "./screens/Business/BusinessBenefit/businessBenefit";
@import "./screens/AboutUs/MainAboutUs/mainAboutUs";
@import "./screens/AboutUs/Team/team";
@import "./sass/components/teamCard";
@import "./screens/Blogs/MainBlogs/mainBlogs";
@import "./sass/components/blogCard2";
@import "./screens/GetApp/MainGetApp/mainGetApp";
@import "./screens/GetApp/Download/downloadGetApp";
@import "./screens/GetApp/BenefitGetApp/benefitGetApp";
@import "./screens/GetApp/AboutApp/aboutApp";
@import "./screens/Login/login";
// @import "./screens/Cab/CabFilter/cabFilter";
@import "./screens/Cab/CabCardBox/cabCardBox";
@import "./screens/Cab/MainCab/mainCab";
@import "./sass/components/cabCard";
@import "./screens/BlogPage/blogPage";
@import "./components/Loading/loading";
@import "./screens/BookCab/bookCab";
@import "./screens/BookCab/BookCabHeader/bookCabHeader";
@import "./screens/BookCab/BookCabMain/bookCabMain";
@import "./screens/BookCab/PaymentCard/paymentCard";
@import "./screens/BookCab/BookCabForm/bookCabForm";
@import "./sass/components/recentBlogCard";
@import "./screens/Cab/CustomerFAQ/customerFAQ";
@import "./screens/PrivacyPolicy/privacyPolicy";
@import "./screens/RefundAndCancellation/refundAndCancellation";
@import "./screens/TermsAndConditions/termsAndConditions";
@import "@fortawesome/fontawesome-free/css/all.min.css";


.whatsapp_float {
    position: fixed;
    width: 55px;
    height: 55px;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: white;
    border-radius: 50%;
    text-align: center;
    font-size: 2rem;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
  
    &:hover {
        background-color: #1ebe57;
        transform: scale(1.3);
    }
}

.whatsapp-icon {
    color: white;
}

a.whatsapp_float {
    text-decoration: none; // Ensures no underline on <a> tag
}