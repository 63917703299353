.margin__bottom-medium{
   margin-bottom: 3rem;
}

.margin__top-medium{
   margin-top: 3rem;
}

.margin__top-small{
   margin-top: 1.5rem;
}

.container{
   display:flex;
   width: 90%;
}

.align-center{
   text-align: center;
}

.align-right{
   text-align: right;
}

.color-secondary{
   color: $color-secondary;
}

.paragraph{
   font-size: 1.4rem;
   line-height: 3rem;
}

.break{
    display: block;
}