.download-get {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 4rem;
    flex-direction: column;

    @media (max-width: 56.25em) {
        padding: 5rem 2rem;
    }

    &__heading {
        font-size: 6.4rem;
        font-weight: 700;
        line-height: 5.76rem;
        color: $color-secondary;
        text-align: center;
        margin-bottom: 1rem;

        @media (max-width: 62.5em) {
            font-size: 5rem;
        }
    }

    &__sub-heading {
        font-size: 4rem;
        font-weight: 700;
        line-height: 5.76rem;
        color: $color-black;
        margin-bottom: 3rem;
        text-align: center;

        @media (max-width: 62.5em) {
            font-size: 3rem;
        }

    }

    &__container{
        margin-bottom: 3rem;
    }
}